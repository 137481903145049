import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Button from "../components/button"

class IndexPage extends React.Component {
  render() {
    const siteTitle = "Greg Bird"

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="Home"
          keywords={[`blog`, `kubernetes`, `kafka`, `elasticsearch`, `devops`, `sre`]}
        />
        {/* <img style={{ margin: 0 }} src="./full_pic.jpg" alt="Gatsby Scene" /> */}
        <h1>
          About Me
        </h1>
        <p>Work and live in Utah. Enjoy mountain biking, hiking, soccer, and being outside. I've been working in DevOps/SRE for the last 4 years at Jolt.</p>
        <p>Enjoy solving new problems using technology. Recent projects include data streaming pipelines using Kafka and ElasticSearch, centralized logging and monitoring system on Kubernetes, and Kubernetes Cluster Management.</p>
        
        <h1>
          Experience
        </h1>
        <p>-> Kubernetes</p>
        <p>-> Docker</p>
        <p>-> AWS</p>
        <p>-> Apache Kafka</p>
        <p>-> ElasticSearch</p>
        <p>-> Nodejs</p>
        <Link to="/blog/">
          <Button marginTop="35px">Go to Blog</Button>
        </Link>
      </Layout>
    )
  }
}

export default IndexPage
